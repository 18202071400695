<template>
  <div class="auth">
    <div style="background-color: #fff; position: relative; height: 100vh">
      <div style="
          font-size:16px;
          color: #333;
          position: fixed;
          top: 30%;
          right: 0;
          left: 0;
          margin: auto;
        " class="loging">
        <img src="http://exshow.oss-cn-shanghai.aliyuncs.com/exshow/imgs/202009/f6458e716e914e6eab1b1c37e94fa15d.gif" />
        <p>努力加载中，请稍候..</p>
        <!-- 参展获取更多商机OidcCallback页面 -->
      </div>
      <div style="position: fixed; bottom: 16%; width: 100%">
        <div>
          <img src="https://img.zhanshangxiu.com/showonline/file/202103/20210325/1f0ae8b7d0d348f9bc52c53eda85c5cf.png" alt="">
          <!-- <img src="https://exshow.oss-cn-shanghai.aliyuncs.com/exshow/imgs/202009/36b5311813a4434f8c410815fa862812.png" /> 灰色没有渐变展商秀logo -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
/* eslint-disable */
import Oidc from "oidc-client";

export default {
  name: "OidcCallback",
  data () {
    return {
      user: null,
    };
  },
  mounted () {
    document.title = '努力加载中';
    console.log("route123", this.$route.query);
    console.log("oidcUser123", this.oidcUser);
    const expiresIn = localStorage.getItem("expires_in");
    // 判断是否是重定向地址返回来 避免重新加载授权出现报错
    if (this.login && this.oidcUser) { 
      location.href = this.redirectUrl;
    } else {
      // 没有登录
      this.init();
    }
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      redirectUrl: "getRedirectUrl",
      oidcUser: "oidcUser",
      token: "getToken",
      login: "getLogin",
      oidcIsAuthenticated: "oidcIsAuthenticated",
    }),
  },
  methods: {
    ...mapMutations({
      setUser: "setUser",
      setUserId: "setUserId",
      setLogin: "setLogin",
      setToken: "setToken",
      setExhibitionId: "setExhibitionId",
      setRedirectUrl: "setRedirectUrl",
    }),
    ...mapActions({
      graphqlPost: "graphqlPost",
      getOidcUser: "getOidcUser",
      oidcSignInCallback: "oidcSignInCallback",
      getExhibitionById: "login/getExhibitionById",
      getUserAdvisorySetting: "roleModule/getUserAdvisorySetting"
    }),
    init () {
      this.oidcSignInCallback()
        .then((redirectPath) => {
          this.getOidcUser().then((user) => {
            localStorage.setItem("access_token", user.access_token);
            localStorage.setItem("expires_in", user.expires_at);
            this.setToken(user.access_token);
            this.setExhibitionId(this.exhibitionId);
            this.getUser();
          });
        })
        .catch((err) => {
          console.error(err);
          // this.$router.push("/oidc-callback-error"); // Handle errors any way you want
        });
    },
    async getUser () {
      let query = `
        query{
          memberQuery{
            current{
              address
              avatarUrl
              balance
              birthday
              businessCard
              city
              company
              country
              county
              createAt
              deleteAt
              department
              email
              followCount 
              id
              inMember(exhibitionId:"${this.exhibitionId}"){ 
                business
                category
                id 
                memberId
                originId
                originLang
                originRemark
                isBlacklist
                originType
                regAt
                sayCount
                sayUserCount
                userId
                userRole
                exhibitorId
                companyUsers{
                  id
                  isAppointment
                }
              }
              introduction
              isDeleted
              jobTitle
              name
              nickName
              phone
              phoneConfirm
              province
              talkCount
              talkSuccessedCount
              title
              updateAt
              userId
            }
          } 
        }
      `;
      let opt = {
        opt: {
          query: query,
        },
        token: this.token,
      };
      let data = await this.graphqlPost(opt);
      console.log(data, "用户信息");
      if (data.data && data.data.memberQuery && data.data.memberQuery.current) {
        this.setLogin(true);
        this.setUser(data.data.memberQuery.current);
        this.getUserAdvisorySetting();
        this.setUserId(data.data.memberQuery.current.userId);
        if (data.data.memberQuery.current && data.data.memberQuery.current.phone) {
          this.$router.push(`/home/index/${this.exhibitionId}`);
        } else {
          this.$router.push(`/auth-bindphone?exhibitionId=${this.exhibitionId}`);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
div {
  text-align: center;
  img {
    display: inline-block;
    max-width: 140px;
  }
}
.loging {
  img {
    width: 120px;
    height: 120px;
  }
}
</style>
